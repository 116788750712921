<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2 class="text-danger">Employee Test Payroll</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'employees'}">Employees</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name:'employee-details', params:{id:$route.params.emp_id}}">
                        {{employee_name}} [IES-{{$route.params.emp_id}}]
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name:'payroll'}">Payroll</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Test Payroll</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}} please refer to error logs</b-alert>
            </div>

            <div v-if="api_success">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="success"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_success}} please refer to error logs</b-alert>
            </div>
            
            <b-row>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>

                <b-col lg="1" class="mb-1rem">
                    <button class="btn btn-danger" @click="onSelection()">
                        Filter
                    </button>
                </b-col>

                <b-col lg="3" class="mb-1rem">
                    <button class="btn btn-danger" v-b-modal.payroll-modal>
                        Generate Test Payroll
                    </button>
                </b-col>
            </b-row>
                <b-table
                    show-empty
                    striped
                    hover
                    no-local-sorting
                    @sort-changed="customSorting"
                    :items="items"
                    :fields="fields"
                    responsive
                    @row-clicked="rowClick"
                    tbody-tr-class="pointer-cursor"
                >
                    <template v-slot:cell(emp_id)="row">
                        {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
                    </template>
                    <template v-slot:cell(month)="row">
                        {{ getMonthName(row.item.month) }}
                    </template>
                    <template v-slot:cell(generated_employee.full_name)="row">
                        {{row.item.generated_employee ? row.item.generated_employee.full_name : 'System' }}
                    </template>
                    <template v-slot:cell(attendance)="row">
                        {{ str_title(row.item.attendance) }}
                    </template>
                    <template v-slot:cell(actions)="row">
                        <div>
                            <router-link
                                :to="{name:'test-payroll-details', params: { test_payroll_id: row.item.id }}"
                            >   
                                <button class="btn-sm btn rounded-circle btn-danger mr-2" v-b-tooltip.hover title="View Sample Payslip Details"> 
                                    <font-awesome-icon icon="eye" size="1x"></font-awesome-icon>
                                </button>
                            </router-link>
                        </div>
                    </template>
                </b-table>
        </b-container>

        <!-- Generate Test Payroll Modal -->
        <b-modal id="payroll-modal" title="Generate Test Payroll" @hide="resetTestPayrollParams">
            <div class="form-group row">
                <label class="col-md-2">
                    Year
                    <span class="text-danger" style="font-weight:bold;">*</span>
                </label>

                <div class="col-md-5">
                    <b-form-select
                        v-model="generate_test_payroll.year"
                        :options="test_payroll_years"
                        disabled-field="notEnabled"
                    ></b-form-select>
                </div>
            </div>
            <div class="form-group row">
                <label
                    class="col-md-2 text-nowrap"
                >Month
                <span class="text-danger" style="font-weight:bold;">*</span>
                </label>
                <div class="col-md-5">
                    <b-form-select
                        v-model="generate_test_payroll.month"
                        :options="months"
                        disabled-field="notEnabled"
                    ></b-form-select>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <b-form-group>
                        <b-form-radio-group
                            id="radio-group-1"
                            v-model="generate_test_payroll.test_generate_payroll_type"
                            :options="generate_payroll_types"
                            name="radio-options"
                        ></b-form-radio-group>
                    </b-form-group>
                </div>
            </div>

            <div slot="modal-footer" class="w-100">
                <b-button
                    class="float-right btn btn-primary"
                    variant="success"
                    v-on:click="generateTestPayroll"
                    style="margin-right:10px;"
                >Generate</b-button>
                <b-button
                    class="float-right btn btn-secondary"
                    variant="secondary"
                    v-on:click="$bvModal.hide('payroll-modal')"
                    style="margin-right:10px;"
                >Cancel</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            year: new Date().getFullYear(),
            api_error: "",
            api_success: "",
            fields: [
                {key: "emp_id", label: 'Employee'},
                { key: "month",sortable: true},
                { key: "gross_salary"},
                {key: "net_pay"},
                {key: "attendance"},
                {key: "generated_employee.full_name", label: 'Generated By'},
                {key: "created_at", label: 'Generated At',sortable: true},
                {key: "actions", label:"Actions"}
            ],
            generate_test_payroll: {
                emp_id: '',
                year: new Date().getFullYear(),
                month: new Date().getMonth()+1,
                test_generate_payroll_type: 'use_full_attendance'
            },
            test_payroll_years: [],
            items: null,
            filter: null,
            employee_name:null,
            access: true,
            years: [],
            total_years:3,
            months: [
                { value: '', text: 'Select Month' },
                { value: 1, text: 'January' },
                { value: 2, text: 'Febuary' },
                { value: 3, text: 'March' },
                { value: 4, text: 'April' },
                { value: 5, text: 'May' },
                { value: 6, text: 'June' },
                { value: 7, text: 'July' },
                { value: 8, text: 'August' },
                { value: 9, text: 'September' },
                { value: 10, text: 'October' },
                { value: 11, text: 'November' },
                { value: 12, text: 'December' }
            ],
            generate_payroll_types: [
                { value: 'use_full_attendance', text: "Use Full Attendance" },
                { value: 'use_real_attendance', text: "Use Real Attendance" },
            ],
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_TEST_PAYROLL");
        this.generate_test_payroll.emp_id = this.$route.params.emp_id;
        this.getYears();
        this.getEmployee();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            this.getTestPayroll(urlBody);
        },

        getYears() {
            const currentYear = (new Date()).getFullYear()+1;
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                });
                this.test_payroll_years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
            this.onPageLoad();
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        getDefaultFilters() {
            let api_params = {
                year: this.year
            };
            return api_params;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.year = api_params.year;
            this.getTestPayroll(api_params);
        },

        getEmployee: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            let data = {
                id: this.$route.params.emp_id
            }
            query["params"] = data;
            this.axios.get(this.$api.get_employees, query).then(response => {
                this.employee_name=response.data.data.full_name;
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        getTestPayroll(params) {
            this.items = [];
            this.startProgressBar();
            var query = this.getAuthHeaders();
            params['emp_id'] = this.$route.params.emp_id;
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_test_employee_payroll, query).then(response => {
                this.items = response.data.data;
                this.updateProgressBar(true);
                delete params.emp_id;
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
        
        generateTestPayroll() {
            this.api_success = '';
            this.api_error = '';
            this.startProgressBar();
            var query = this.getAuthHeaders();
            let params = this.deleteEmptyKeys(this.generate_test_payroll);
            query["params"] = {};
            this.axios.post(this.$api.generate_test_payroll, params, query).then((response) => {
                this.onSelection();
                this.$bvModal.hide('payroll-modal');
                this.updateProgressBar(true);
                if (response.data.code == 200) {
                    this.api_success = response.data.message;
                    this.showAlert();
                }
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.$bvModal.hide('payroll-modal');
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        resetTestPayrollParams() {
            this.generate_test_payroll.year = new Date().getFullYear();
            this.generate_test_payroll.month = new Date().getMonth()+1;
            this.generate_test_payroll.test_generate_payroll_type = 'use_full_attendance';
        },

        onSelection() {
            this.getTestPayroll(this.getDefaultFilters());
        },

        rowClick(item){
            this.$router.push({name:'test-payroll-details', params: { test_payroll_id: item.id,emp_id:item.emp_id }});
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.pointer-cursor {
    cursor:pointer !important;
}

</style>